.app {
  border: 1px solid black;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #EEEEEE;
  overflow-y: auto;
  overflow-x: auto;
}

.container {
  margin: 0 auto;
  max-width: 800px;
  align-self: center;
  padding: 20px 80px;
  background-color: #FFFFFF;
}